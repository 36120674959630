import React from 'react';
import { Link } from 'gatsby';
import '../assets/styles/components/footer.scss';
import logoImage from '../images/eardrum-logo.gif';

const Footer = () => (
  <footer id="section-contact">
    <div className="container">
      <div className="row">
        <div className="footer-section">
          <h2>Contact us</h2>
          <div className="contact-wrapper">
            <ul className="list-contact first-col column">
              <li>
                <a href="tel:+612 8572 5400" rel="noopener norefferer" className="icon-mobile">
                  +61 2 8572 5400
                </a>
              </li>
              <li>
                <Link to="https://www.google.com/maps/place/Level+1%2F16+Kent+St,+Millers+Point+NSW+2000/@-33.8586749,151.201598,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12ae4499956323:0xb416fa46284c1ac1!8m2!3d-33.8586749!4d151.2037867" className="icon-location">
                  Level 1, 16 Kent Street Millers Point,
                  Sydney 2000, Australia.
                </Link>
              </li>
            </ul>
            <ul className="list-contact first-col column">
              <li>
                <a href="mailto:info@eardrum.com.au" rel="noopener norefferer" className="icon-envelop">
                  info@eardrum.com.au
                </a>
              </li>
              <li>
                <Link to="https://www.instagram.com/eardrumcreative/" className="icon-instagram">
                  @eardrumcreative
                </Link>
                <br />
                <Link to="https://www.facebook.com/earfest2020/" className="icon-facebook">
                  @earfest2020
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-section logo-section">
          <a href="http://eardrum.com/" target="_blank" rel="noreferrer">
            <img src={logoImage} className="footer-logo" alt="eardrum logo" />
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
