import React, { useState } from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import '../assets/styles/components/header.scss';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <header className={`container ${showMenu ? 'is-open' : ''}`}>
      <div className="row">
        <div className="hamburger-wrapper">
          <button
            className="hamburger hamburger--squeeze"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setShowMenu(!showMenu);
            }}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
        <ul className="navigation-wrapper">
          <li>
            <Link to="/" activeClassName="active" className="navigation-link">
              Home
            </Link>
          </li>
          <li>
            <AnchorLink to="/#section-artists" className="navigation-link">
              view The Artists
            </AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#section-contact" className="navigation-link">
              contact us
            </AnchorLink>
          </li>
          <li>
            <Link to="/about" activeClassName="active" className="navigation-link">
              About Us
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
